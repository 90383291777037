import './MainSection.scss';

import {faChevronDown} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {AnimatePresence, motion} from 'framer-motion';
import React, {useState} from 'react';

import Blogs from '../Blogs/Blogs';
import Footer from '../Footer/Footer';
import moreInfo from './data/moreInfo.json';
import team from './data/team.json';

const MainSection = () => {
  const [openSections, setOpenSections] = useState({});

  const toggleSection = (index) => {
    setOpenSections((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <div id='about' className='main'>
      <motion.div
        className='announcement-banner'
        initial={{opacity: 0, y: 20}}
        animate={{opacity: 1, y: 0}}
        transition={{duration: 0.6}}
      >
        <p>
          We recently achieved a technical breakthrough and are scaling the team to build something new and ambitious.
          <br />
          <br />
          Our agents will soon move beyond games and into your daily life.
        </p>
      </motion.div>

      <motion.div
        className='main_container'
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        transition={{duration: 0.4, delay: 0.8}}
      >
        <h1 className='main_top'>
          <span>
            Altera is an applied research company building digital humans: machines with fundamental human qualities.
          </span>
        </h1>

        <div className='main_middle'>
          <p>
            We shut down our lab at MIT on December 1st, 2023 to start Altera.
            <br /> <br />
            We are led by{' '}
            <a
              href='https://scholar.google.com/citations?user=hrI8aH8AAAAJ&hl=en'
              target='_blank'
              rel='noopener noreferrer'
            >
              Dr. Robert Yang
            </a>{' '}
            and are a small team of computational neuroscientists, CS and Physics medalists, researchers, engineers, and
            builders from MIT EECS, Stanford NLP Group, Google X, Citadel, and more.
            <br /> <br />
            Our mission is to create digital human beings that live, care, and grow with us.
            <br /> <br />
            Our early research prototypes started in games as a rapid testing environment. Try{' '}
            <a href='https://playlabs.altera.al/discover' target='_blank' rel='noopener noreferrer'>
              Playlabs
            </a>
            , for the first version of these digital humans -- friends that can play games with you.
          </p>
        </div>

        <div className='main_blogs_section'>
          <Blogs />
        </div>

        <div className='main_bottom'>
          <div className='main_bottom_firstSection'>
            <div className='dropdown-container'>
              {moreInfo.map((info, i) => (
                <div key={i} className='dropdown-section'>
                  <div className='dropdown-header' onClick={() => toggleSection(i)} style={{cursor: 'pointer'}}>
                    <h1>{info.title}</h1>
                    <motion.div
                      animate={{rotate: openSections[i] ? 180 : 0}}
                      transition={{duration: 0.3}}
                      className='dropdown-arrow'
                    >
                      <FontAwesomeIcon icon={faChevronDown} />
                    </motion.div>
                  </div>
                  {(i !== moreInfo.length - 1 || openSections[i]) && (
                    <div
                      style={{
                        height: '1px',
                        backgroundColor: '#dcdcdc91',
                        margin: '1rem 0',
                      }}
                    />
                  )}

                  <AnimatePresence>
                    {openSections[i] && (
                      <motion.div
                        initial={{height: 0, opacity: 0}}
                        animate={{height: 'auto', opacity: 1}}
                        exit={{height: 0, opacity: 0}}
                        transition={{duration: 0.3}}
                        style={{overflow: 'hidden'}}
                      >
                        {info.description.map((desc, j) => (
                          <p className='main_bottom_firstSection_paragraph' key={j}>
                            {desc}
                          </p>
                        ))}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              ))}
            </div>
          </div>
          <div className='main_bottom_last'>
            <h1>Team</h1>
            <div className='main_bottom_last_grid'>
              {team.map((person, i) => (
                <motion.div key={i} className='main_bottom_last_grid_unit'>
                  <div className='main_bottom_last_grid_unit_name'>
                    <h2>{person.name}</h2>
                    <p className={`pastel${Math.floor(Math.random() * 6) + 1}`}>{person.job}</p>
                  </div>
                  <p className='main_bottom_last_grid_unit_info'>{person.description}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
        <Footer />
      </motion.div>
    </div>
  );
};

export default MainSection;
