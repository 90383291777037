import './Blogs.scss';

import React, {useEffect, useState} from 'react';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {motion} from 'framer-motion';

import blogs from './data/blogs.json';

const Blogs = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 430);
  const blogsPerView = isMobile ? 1 : 3;
  const maxIndex = blogs.length - blogsPerView;

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 430;
      setIsMobile(mobile);
      // Reset index if switching between mobile and desktop
      setCurrentIndex(0);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleNext = () => {
    setCurrentIndex((prev) => Math.min(prev + 1, maxIndex));
  };

  const handlePrev = () => {
    setCurrentIndex((prev) => Math.max(prev - 1, 0));
  };

  const visibleBlogs = blogs.slice(currentIndex, currentIndex + blogsPerView);

  return (
    <div className='blogs'>
      <div className='blogs_header'>
        <h3>Recent Research</h3>
        <div className='blogs_navigation'>
          <button className='blogs_nav' onClick={handlePrev} aria-label='Previous blog' disabled={currentIndex === 0}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <button
            className='blogs_nav'
            onClick={handleNext}
            aria-label='Next blog'
            disabled={currentIndex === maxIndex}
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      </div>
      <div className='blogs_wrapper'>
        <motion.div
          className='blogs_container'
          initial='hidden'
          animate='visible'
          variants={{
            visible: {
              transition: {
                staggerChildren: 0.1,
              },
            },
          }}
        >
          {visibleBlogs.map((blog, i) => (
            <motion.div
              key={currentIndex + i}
              className='blogs_card'
              onClick={() => window.open(blog.link, '_blank')}
              variants={{
                hidden: {opacity: 0, x: 20},
                visible: {opacity: 1, x: 0},
              }}
            >
              <img src={blog.img} alt={blog.title} />
              <h4>{blog.title}</h4>
              <p className='blogs_date'>{blog.date}</p>
              {!blog.desc_link ? (
                <p>{blog.description}</p>
              ) : (
                <p>
                  <a
                    className='blogs_desc_link'
                    href={blog.desc_link}
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={(e) => e.stopPropagation()}
                  >
                    {blog.description}
                  </a>
                </p>
              )}
            </motion.div>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default Blogs;
