import './Header.scss';

// import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {motion} from 'framer-motion';
import React from 'react';

import {useScrollDirection} from '../../hooks/useScrollDirection';

const Header = () => {
  const {scrollDirection, scrolledPastThreshold} = useScrollDirection();
  const shouldShowHeader = scrollDirection === 'up' || !scrolledPastThreshold;

  return (
    <motion.div
      className={`header ${scrolledPastThreshold ? 'header--floating' : ''}`}
      initial={{y: 0}}
      animate={{y: shouldShowHeader ? 0 : -100}}
      transition={{duration: 0.3}}
    >
      <motion.div
        initial={{opacity: 0, y: -20}}
        animate={{opacity: 1, y: 0}}
        transition={{duration: 0.2, delay: 0.6}}
        className='header_content'
      >
        <div className='header_left'>
          <a href='/' className='header_logo'>
            <img src='./img/logo_dark.png' alt='Altera Logo' />
          </a>
        </div>
        <div className='header_right'>
          <nav className='header_nav'>
            <a href='https://substack.com/@digitalhumanity' target='_blank' rel='noopener noreferrer'>
              Blog
            </a>
            <a href='mailto:contact@altera.al'>Contact</a>
          </nav>
          <motion.div whileHover={{y: -2}} className='header_cta'>
            <button onClick={() => window.open('https://jobs.ashbyhq.com/altera.al', '_blank')}>
              <p>We&apos;re hiring</p>
              {/* <FontAwesomeIcon icon={faChevronRight} /> */}
            </button>
          </motion.div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default Header;
